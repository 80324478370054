import React from "react";
import DonutChart from "react-donut-chart";
import { Doughnut } from "react-chartjs-2";
import "../../styling/Donut.css";

const AnalyticsDonut = () => {
  // const data = [ {
  //     label: 'Booked',
  //     value: 75,
  //     color: '#B5A6FF'
  // }, {
  //     label: 'Non-booked',
  //     value: 20,
  //     color: '#260F99'
  // },{
  //     label: 'Cancelled',
  //     value: 5,
  //     color: '#D9D9D9'
  // }

  // ];

  const data = {
    labels: ["Booked", "Non-booked", "Cancelled"],
    datasets: [
      {
        data: [50, 45, 5],
        backgroundColor: ["#260F99", "#B5A6FF", "#D9D9D9"],
        hoverBackgroundColor: ["#260F99", "#B5A6FF", "#D9D9D9"],
      },
    ],
  };

  return (
    <div className="right-donut">
      <h2 style={{ paddingLeft: "10px" }}>Success Rate</h2>
      <div className="donut">
        {/* <DonutChart data={data} height={190} width= {190} legend={false} colors={data.map(item => item.color)} clickToggle={false} /> */}
        <Doughnut
          data={data}
          options={{
            cutout: '80%',
            plugins: {
              legend: {
                display: true,
                position: "bottom", // Position the legend below the chart
                labels: {
                    generateLabels: (chart) => {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                          return data.labels.map((label, i) => {
                            const value = data.datasets[0].data[i];
                            const backgroundColor = data.datasets[0].backgroundColor[i];
                            return {
                              text: `${label}: ${value}%`, // Display label with value
                              fillStyle: backgroundColor,
                              strokeStyle: backgroundColor,
                              lineWidth: 0.5,
                            };
                          });
                        }
                        return [];
                      },
                  boxWidth: 20,
                  padding: 20, // Adjust padding between legend items
                },
              },
            },
          }}
          width={170}
          height={170}
        />
      </div>
    </div>
  );
};

export default AnalyticsDonut;
