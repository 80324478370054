// ProductModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import '../../styling/ProductModal.css';
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const ProductModal = ({isModalOpen, closeModal  }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const liStyle = {
    marginBottom: '15px'
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <span className="close" onClick={closeModal}>
        &times;
      </span>

      <div className="plans" style={{ padding: '5%'}}>
        <div className={`plan ${selectedPlan === 'Starter' ? 'pselected' : ''}`} onClick={() => handlePlanSelect('Starter')}>
          <h2 style={{ color: '#1C1154'}} >Starter</h2>

          <div style={{ display: 'flex', justifyItems: 'center', marginBottom: '0px'}}>
          <h1 style={{ color: '#1C1154', fontSize: '56px', marginTop: '25px', marginBottom: '0px'}}>$25</h1><p style={{marginTop: '45px', marginLeft: '10px' }}>/month for one year<br/> (billed annually)</p>
          </div>
          <p>Start with everything your need to sprout your way to the top!</p>
          <p>What's included: </p>
          <ul className='modalUl'>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Unlimited clients and event bookings</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Access to Book-A-Booth</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Invoices and payments</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Proposals</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Questionnaires</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Photostrip Template Feedback</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Galleries</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Upload 5,000 photos</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Calendar</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> Analytics</li>
            <li style={liStyle}><IoIosCheckmarkCircleOutline /> ClientPortal</li>
          </ul>
          <button className={`planButton ${selectedPlan === 'Starter' ? 'bselected' : ''}`}> Start Free Trial</button>
        </div>

        <div className={`plan ${selectedPlan === 'Basic' ? 'pselected' : ''}`} onClick={() => handlePlanSelect('Basic')}>
          <h2 style={{ color: '#1C1154'}}>Basic</h2>
          <div style={{ display: 'flex', justifyItems: 'center'}}>
          <h1 style={{ color: '#1C1154', fontSize: '56px', marginTop: '25px', marginBottom: '0px'}}>$35</h1><p style={{marginTop: '45px', marginLeft: '10px' }}>/month for one year<br/> (billed annually)</p>
          </div>
          <p>Start with everything your need to sprout your way to the top!</p>
          <p>What's included: </p>
          <ul className='modalUl'>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> All Starter Features</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> Add upto 2 team members</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> Upload 5,000 images</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> All Starter Features</li>
          </ul>
          <button className={`planButton ${selectedPlan === 'Basic' ? 'bselected' : ''}`} > Start Free Trial</button>
        </div>

        <div className={`plan ${selectedPlan === 'Bloom' ? 'pselected' : ''}`} onClick={() => handlePlanSelect('Bloom')}>
          <h2 style={{ color: '#1C1154'}} >Bloom</h2>

          <div style={{ display: 'flex', justifyItems: 'center'}}>
          <h1 style={{ color: '#1C1154', fontSize: '56px', marginTop: '25px', marginBottom: '0px'}}>$50</h1><p style={{marginTop: '45px', marginLeft: '10px', marginBottom: '0px' }}>/month for one year<br/> (billed annually)</p>
          </div>
          <p>Stater package to help bloom your business to new peaks.</p>
          <p>What's included: </p>
          <ul className='modalUl'>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> All Bloom Features</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> Unlimited team members</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> Upload 5,000 images</li>
          <li style={liStyle}><IoIosCheckmarkCircleOutline /> Whitelabel Emails</li>
          </ul>
          <button className={`planButton ${selectedPlan === 'Bloom' ? 'bselected' : ''}`}> Start Free Trial</button>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;