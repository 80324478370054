import "../styling/App.css";
import React, {  useState } from "react";
import LeftMenu from "./LeftMenu";
import Content from "./Content";
import Header from "./header";
import BusinessClientView from "./BusinessClientView";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import BookABooth from "./BookABooth";
import Views from "./Views";
import BookABooth2 from "./BookABooth2";
import Login from "./Login";
import Password from "./Password";
import SignUp from "./SignUp";
import Membership from "../components/payments/Membership";
import StripePayment from "../components/payments/StripePayment";
import PaymentSuccess from "../components/payments/PaymentSuccess";
import StripeOnboarding from "./StripeOnboarding";


function App() {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedClientView, setSelectedClientView] = useState(true);

  

  const [clientRecord, setClientRecord] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem.text);
  };

  

  const onClientClick = (record) => {
    setClientRecord(record);
    setSelectedClientView(!selectedClientView);
  };

  const onClickBacktoAccount = () => {
    setSelectedClientView(!selectedClientView);
  };

  const handleDropdownClick = (account) => {
    setSelectedMenuItem(account);
    setSelectedClientView(true);
  };

  return (
    <Router>
      <div className="appContain">
        {
        <Header
          onClickAccountInfo={handleDropdownClick}
          selectedMenuItem={selectedMenuItem}
        />
        }
        <Routes>
        <Route exact path="/" element={<Views handleMenuItemClick={handleMenuItemClick} selectedMenuItem={selectedMenuItem} />} />
          <Route exact path="/booth2" element={<BookABooth2 />} />
          <Route exact path="/booth" element={<BookABooth />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/login" element={<Login />}/>
          <Route exact path="/password" element={<Password />} />
          <Route exact path="/membership" element={<Membership />} />
          <Route exact path="/payment" element={<StripePayment />} />
          <Route exact path="/success" element={<PaymentSuccess />} />
          <Route exact path="/stripeOnboarding" element={<StripeOnboarding />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
