import logo from "../../images/BloomBoothLogo.png";

const Membership = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '24rem', backgroundColor: '#e5e7eb', boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)' }}>
                <figure style={{ padding: '2.5rem 2.5rem 0' }}>
                    <img
                        src={logo}
                        alt="Membership"
                        style={{ maxWidth: '100%' }}
                    />
                </figure>
                <div style={{ padding: '1.25rem' }}>
                    <h2 style={{ fontSize: '1.5rem', fontWeight: '700' }}>
                        Premier Package
                    </h2>
                    {/* <p>
                        
                    </p> */}
                    <p>
                        <b>Membership Price: $550.00</b>
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a
                            style={{
                                display: 'inline-block',
                                padding: '0.75rem 1.25rem',
                                backgroundColor: '#3b82f6',
                                borderRadius: '0.5rem',
                                color: '#fff',
                                marginTop: '1.25rem',
                                textDecoration: 'none',
                                textAlign: 'center'
                            }}
                            href="/payment"
                        >
                            Purchase
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Membership;
