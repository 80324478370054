import React, { useState } from "react";
import Table from "rc-table";
import "../../styling/TableDashboard.css";
import { PiCaretUpDownFill } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";

const TableDashboard = () => {
    const [sortOrder, setSortOrder, selectedRows, setSelectedRows] = useState({});

    const columns = [
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: () => <input type="checkbox" />,
            width: 9,
            align: "center",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Client
                </div>
            ),
            dataIndex: "client",
            key: "client",
            width: 100,
            align: "left",
            render: (text, record) => (
                <a
                    href={`/client/${record.clientId}`}
                    style={{ color: "#1C1154", textDecoration: "none" }}
                >
                    <h4 style={{ margin: "0px" }}> {text} </h4>
                </a>
            ),
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Issued
                </div>
            ),
            dataIndex: "issued",
            key: "issued",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Due
                </div>
            ),
            dataIndex: "due",
            key: "due",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("status")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Status
                </div>
            ),
            dataIndex: "status",
            key: "status",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div
                    className="custom-header"
                    onClick={() => handleSort("paymentAmount")}
                >
                    <PiCaretUpDownFill className="table-header-icon" />
                    Payment Amount
                </div>
            ),
            dataIndex: "paymentAmount",
            key: "paymentAmount",
            width: 100,
            align: "left",
        },
    ];

    const data = [
        {
            client: "Jack Nicholson",
            issued: "June 13,2023",
            due: "July 30,2023",
            status: "Paid",
            paymentAmount: "$250",
            key: "1",
        },
        {
            client: "Al Pacino",
            issued: "June 13,2023",
            due: "July 30,2023",
            status: "Paid",
            paymentAmount: "$250",
            key: "2",
        },
        {
            client: "Robert De Niro",
            issued: "June 13,2023",
            due: "July 30,2023",
            status: "Paid",
            paymentAmount: "$250",
            key: "3",
        },
    ];

    const handleSort = (column) => {
        setSortOrder((prevSortOrder) => ({
            ...prevSortOrder,
            [column]: prevSortOrder[column] === "asc" ? "desc" : "asc",
        }));
        // Add your sorting logic here
    };

    const handleSelectAll = (checked) => {
        const allRows = data.map((item) => item.key); // Assuming you have a unique key for each row
        setSelectedRows(checked ? allRows : []);
    };

    const handleDelete = () => {
        // Implement delete logic using selectedRows
        console.log("Deleting rows:", selectedRows);
    };

    const handleDownload = () => {
        // Implement download logic using selectedRows
        console.log("Downloading rows:", selectedRows);
    };

    const rowClassName = () => "custom-table-row";

    const customFooter = () => (
        
          
            
                <div style={{ marginLeft: '14px', display: 'flex', alignItems: 'baseline'}}>
                <input type="checkbox" onChange={(e) => handleSelectAll(e.target.checked)} />
                <span style={{ margin: '0 4px' }} />

          {/* Download Button */}
          <button className="tableDownloadButton" onClick={() => handleDownload()}>Download</button>
                </div>
            
          
       
      );

    //   <tfoot>
    //   <tr>
    //     <td colSpan={columns.length}>
    //       {/* Checkbox */}
    //       <input type="checkbox" id="selectAll" />
    //       <label htmlFor="selectAll">Select All</label>

    //       {/* Gap */}
    //       <span style={{ margin: '0 4px' }} />

    //       {/* Download Button */}
    //       <button onClick={() => handleDownload()}>Download</button>
    //     </td>
    //   </tr>
    // </tfoot>

      const customRowClassName = (record, index) => {
        // Add a class to every row for the bottom border
        return 'table-row-with-border';
      };

    return (
        <div>
        <div className="customDashboardTable">
            <div
                style={{
                    paddingTop: "25px",
                    paddingLeft: "15px",
                    display: "flex",
                    justifyContent: "left",
                    width: "37.5%",
                    alignItems: "baseline",
                }}
            >
                <h2
                    style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#1C1154",
                        marginTop: "0px",
                        paddingRight: "19px",
                    }}
                >
                    {" "}
                    Invoices
                </h2>
                <div style={{ color: "#4D4D4D" }}>
                    {"Total: "}
                    {115}
                </div>
            </div>
        <div
          style={{ position: "relative", width: "81%", marginTop: "13px", marginLeft: '12px' }}
        >
          <div
            style={{
              position: "absolute",
              left: "10px",
              top: "13px",
              color: "gray",
            }}
          >
            <FaSearch />
          </div>
          <input
            type="text"
            style={{ padding: "10px", paddingLeft: "40px", width: "100%" }}
            placeholder="Search"
          />
        </div>
            <Table
                columns={columns}
                data={data}
                scroll={{ x: "100%" }}
                rowClassName={(record, index) => `${rowClassName(record, index)} ${customRowClassName(record, index)}`}
                components={{
                    header: {
                        cell: (props) => (
                            <th
                                {...props}
                                style={{
                                    borderBottom: "1px solid #D9D9D9",
                                    height: "50px",
                                    textAlign: "left",
                                }}
                            >
                                {props.children}
                            </th>
                        ),
                    },
                }}
                footer={customFooter}
            />

            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', height: '50px' }}>
        <div>
          <input type="checkbox" onChange={(e) => handleSelectAll(e.target.checked)} />
          <span>Select All</span>
        </div>
        <div>
          <button onClick={handleDelete}>Delete</button>
          <button onClick={handleDownload}>Download</button>
        </div>
        <div>Pagination Controls</div>
      </div> */}
        </div>
        </div>
    );
};

export default TableDashboard;
