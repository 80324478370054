import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
 
const PAYMENT_SUCESS_URL = "http://localhost:3000/success";
 
const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
 
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) return;
 
        setIsLoading(true);
        setMessage("Payment in Progress");
 
        const resp = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: PAYMENT_SUCESS_URL,
            },
        });
 
        if (resp.error) setMessage("Some Error Occurred !!");
        setIsLoading(false);
    };
 
    return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            <form onSubmit={handleSubmit}>
                <div style={{ width: '100%', backgroundColor: '#e5e7eb', boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)', borderRadius: '0.5rem' }}>
                    <div style={{ padding: '1.5rem' }}>
                        <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
                            Complete your payment here!
                        </h1>
                        <PaymentElement />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
                            <button
                                style={{
                                    padding: '0.5rem 1rem',
                                    backgroundColor: '#3b82f6',
                                    borderRadius: '0.75rem',
                                    color: '#fff',
                                    textAlign: 'center',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                disabled={isLoading || !stripe || !elements}
                            >
                                {isLoading ? "Loading..." : "Pay now"}
                            </button>
                        </div>
                        {message && <div>{message}</div>}
                    </div>
                </div>
            </form>
        </div>
    );
};
 
export default PaymentForm;
