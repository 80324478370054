import React from 'react';
import { Chart as ChartJS, LinearScale, Legend } from "chart.js/auto";
import { Line } from 'react-chartjs-2';

const DashboardLineChart = ({data, options}) => {
  ChartJS.register(LinearScale, Legend);
  return (
    <div style={ { width: '72%', height: '100%', backgroundColor: '#FFFFFF', paddingLeft: '1.35%', paddingTop: '1%' } }>
      <Line data={data} options={options} />
    </div>
  );
};

export default DashboardLineChart;
