import React from "react";
import "../styling/LeftMenu.css";
import { IoHome } from "react-icons/io5";
import { BsBookmark, BsCalendar } from "react-icons/bs";
import { BiSolidBarChartAlt2 } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";

const LeftMenu = ({ selectedMenuItem, onMenuItemClick }) => {
  const menuItems = [
    { text: "Dashboard", icon: <IoHome className="icon" /> },
    { text: "Bookings", icon: <BsBookmark className="icon" /> },
    { text: "Clients", icon: <FaRegUserCircle className="icon" /> },
    { text: "Calendar", icon: <BsCalendar className="icon" /> },
    { text: "Analytics", icon: <BiSolidBarChartAlt2 className="icon" /> },
  ];

  return (
    <div className="left-menu">
      <ul>
        {menuItems.map((menuItem, index) => (
          <li
            key={index}
            className={selectedMenuItem === menuItem.text ? 'selected' : ''}
            onClick={() => onMenuItemClick(menuItem)}
          >
            <span className="indicator"></span>
            {menuItem.icon} {menuItem.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftMenu;
