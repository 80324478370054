import React, {useState} from "react";
import '../../styling/Dashboard.css';

import profilePic from "../../images/profilePic.jpg";
import axios from 'axios';

const  OverviewRightPanel = () => {

  const [paymentIntentId, setPaymentIntentId] = useState('');
    const [deductionAmount, setDeductionAmount] = useState('');
    const [refundDetails, setRefundDetails] = useState(null);
    const [error, setError] = useState(null);
  const handleRefund = async (event) => {
    event.preventDefault();

    try {
        const response = await axios.post('http://localhost:3001/create-refund', {
            paymentIntentId,
            deductionAmount: parseFloat(deductionAmount),
        });

        setRefundDetails(response.data);
        setError(null);
    } catch (err) {
        setError(err.response ? err.response.data.error : 'An error occurred');
        setRefundDetails(null);
    }
};

    const memberInfo = (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "4px",
            padding: "2px",
          }}
        >
          <div
            style={{ borderRadius: "50%", overflow: "hidden", marginRight: "10px" }}
          >
            {/* Replace the URL with the actual path to your profile picture */}
            <img
              src={profilePic}
              alt="Profile"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
          <div>
            <h4 style={{ margin: "0", fontWeight: "500", color: "#1C1154" }}>
              John Doe
            </h4>
            <p style={{ margin: "0", color: "#4D4D4D" }}>Software Engineer</p>
          </div>
        </div>
      );


  return (
    
    <div className="right-panel" style={{ marginTop: '75px', width: '22%', height: 'auto'}}>
        <button style={{ width: '60%', height: '35px', marginLeft: '40%', color: '#1C1154', fontWeight: '400', fontSize: '15px', background: '#ffffff', border: '1px solid #D9D9D9'}}>View All Events</button>
      <div >

          <div className="right-panel-content">
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              Event Total:
            </h4>
            <div style={{ display: 'flex'}} >
            <h1 style={{ color: "#1C1154",marginBottom: '20px', marginTop: '5px'}}> $400</h1>
            <div style={{ backgroundColor: '#E1FAE3', color: '#21A62C', padding: '10px', position: 'absolute', right: '9%'  }}>
                Paid
            </div>
            </div>
          </div>
          <div className="right-panel-content" style={{ marginTop: '0px', borderTop: 'hidden'}}>
            <div className="right-panel-arrow-container">
              <span><h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              Premier Package
            </h4></span>{" "}
              <div style={{ alignSelf: 'flex-end', fontSize: '15px', marginRight: '10px', marginBottom: '5px', color: "#1C1154", fontWeight: '525' }}> $250 </div>
            </div>

            <div style={{ color: "#4D4D4D" }}>
              <p style={{ marginBottom: "3px" }}>3 Hours On-Site Attendant</p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Unlimited Prints
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Online Downloads
              </p>
              <p style={{ marginBottom: "3px", marginTop: "3px" }}>
                Party Props Custom
              </p>
              <p style={{ marginBottom: "10px", marginTop: "3px" }}>
                Photostrips Design
              </p>
            </div>
          </div>
          <div className="right-panel-content" style={{ marginTop: '0px', borderTop: 'hidden'}}>
          <div className="right-panel-arrow-container">
              <span><h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              Add Ons
            </h4></span>{" "}
              <div style={{ alignSelf: 'flex-end', fontSize: '15px', marginRight: '10px', marginBottom: '5px', color: "#1C1154", fontWeight: '525' }}> $50 </div>
            </div>

            <div style={{ color: '#4D4D4D'}}>
              <p>
              Group Photo <br />
              Custom Backdrop
              </p>
            </div>
          </div>
        </div>
        <div className="members-panel" style={{ marginTop: '0px', width: '95.4%', marginRight: '10px'}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          width: "87%",
        }}
      >
        <h3
          style={{
            fontWeight: "500",
            color: "#1C1154",
            marginTop: "0px",
            fontSize: "20px",
          }}
        >
          {" "}
          Assigned Team Members
        </h3>
      </div>
      <div>
        {memberInfo}
        {memberInfo}
        {memberInfo}
      </div>
      <div style={{ marginTop: "15px", marginBottom: '30px' }}>
            <button className="addTeamMemberButton" style={{ background: '#ffffff', border: '1px solid #D9D9D9', color: '#1C1154', fontWeight: '550'}} onClick={handleRefund}>  Cancel </button>
      </div>
    </div>
    </div>
  );
};

export default OverviewRightPanel;
