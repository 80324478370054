import React, { useState } from 'react';
import '../styling/SignUp.css'

const SignUp = () => {
      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: ''
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = () => {
        // Send formData to your API
        console.log('Sending formData:', formData);
        // Example fetch request to send formData to an API
        fetch('https://example.com/api/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
        .then(response => {
          if (response.ok) {
            console.log('Form data submitted successfully');
          } else {
            console.error('Failed to submit form data');
          }
        })
        .catch(error => {
          console.error('Error submitting form data:', error);
        });
      };
    
      return (
        <div className='login'>
          <div style={{ padding: '13%' }}>
            <div style={{ fontSize: '35px', color: '#1C1154' }}>
              Try BoothBloom free for 7 days
            </div>
            <div style={{ paddingLeft: '30%', color: '#4D4D4D' }}>
              <p>No Credit Card Required</p>
            </div>
    
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="login-input"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="login-input"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="login-input"
              value={formData.email}
              onChange={handleInputChange}
            />
            <button className="login-button" onClick={handleSubmit}>
              Next &gt;
            </button>
            

            <div style={{ paddingLeft: '30%', color: '#545454'}}>
            <p> Go Back to  <span style={{ textDecoration: 'underline' }}>login page</span></p>
            </div>
          </div>
        </div>
      );
};

export default SignUp;