import React, {useState} from 'react';
import EventInfoTile from './tiles/EventInfoTile';
import CreateBookingModal from './modals/CreateBookingModal';
import ClientsTable from './tables/ClientsTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Clients = ({handleClientClick}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openNewBookingsModal = () => {
        setIsModalOpen(true);
      };

    return(
        <div>
             <div>
            <button type="button"  className="events-add-button" onClick={openNewBookingsModal}>
               + Add New Client
            </button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
           <h1 style={{ color: '#1C1154', margin:'30px' }}>Clients</h1>
           
           <EventInfoTile/>
           <ClientsTable handleClientClick={handleClientClick}/> </div>
    
           <CreateBookingModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
            />
        </div>
    );
};

export default Clients;