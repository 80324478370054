import React from "react";

import "../../styling/Dashboard.css";
import profilePic from "../../images/happy-birthday-background-sign-tropical-beach-hawaii-63372910.jpg.webp";
import profilePicture from "../../images/John-Wick.jpg.avif";



const LeftClientPanel = ({handleBackToAccount}) => {
  const clientInfo = (
    <div
    >
         <div>
            <img src={profilePic} alt="cover" style={{ width: '90%', height: '150px' }} />
            <img
          src={profilePicture}
          alt="Profile"
          style={{ width: "50px", height: "50px", borderRadius: '50%', overflow: 'hidden', marginTop: '-28px', marginLeft:'25px' }}
        />
        
    </div>
      <div style={{  marginLeft: "25px", fontWeight: "500", color: "#1C1154" }}>
        <h2>
          John Wick
        </h2>
        <h4 style={{ marginBottom: '-5px'}}>Phone</h4>
        <p style={{ color: '#4D4D4D'}}>9094583-960</p>
        <h4 style={{ marginBottom: '-5px'}}>Email</h4>
        <p style={{ color: '#4D4D4D'}}>johnwich@gmail.com</p>
        <h4 style={{ marginBottom: '-5px'}}>Most Recent Booking</h4>
        <p style={{ color: '#4D4D4D'}}>Mar 21, 2024</p>
        <h4 style={{ marginBottom: '-5px'}}>Total Events Booked</h4>
        <p style={{ color: '#4D4D4D'}}>5</p>
      </div>
      <div style={{ marginLeft: "25px" }}>
            <button className="backToAcButton" onClick={handleBackToAccount}> View All Events </button>
          </div>
    </div>
  );

  return (
    <div>
        
      
       

      <div>
        {clientInfo}
      </div>
    </div>
  );
};

export default LeftClientPanel;
