import React from 'react';
import { Chart as ChartJS, LinearScale, Legend } from "chart.js/auto";
import { Line } from 'react-chartjs-2';

const AnalyticsLineChart = () => {
  ChartJS.register(LinearScale, Legend);

  const months = ['Sep', 'Oct 2023', 'Nov 2023', 'Dec 2023', 'Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024', 'Aug 2024'];

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Online Bookings',
        data: [150, 200, 180, 220, 240, 260, 280, 300, 320, 340, 360, 380],
        borderColor: '#260F99',
        backgroundColor: 'rgba(38, 15, 153, 0.2)',
        fill: true,
      },
      {
        label: 'Offline Bookings',
        data: [100, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220],
        borderColor: '#B5A6FF',
        backgroundColor: 'rgba(181, 166, 255, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          padding: 10, // Adjust padding around the legend
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      title: {
        display: true,
        align: 'start',
        text: 'Monthly Bookings - Online vs Offline',
        color: '#1C1154',
        font: {
          size: 24,
          family: 'Poppins',
        },
      },
      tooltip: {
        enabled: true,
      }
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Bookings',
        },
      },
    },
  };


  return (
    <div style={ { width: '72%', height: '100%', backgroundColor: '#FFFFFF', paddingLeft: '1.35%', paddingTop: '1%' } }>
      <Line data={data} options={options} />
    </div>
  );
};

export default AnalyticsLineChart;