// FileUploadComponent.jsx

import React, { useState, useRef } from 'react';
import '../../styling/FileUploadComponent.css'; // Add your CSS file for styling
import { ImDownload3 } from "react-icons/im";

const FileUploadComponent = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (event) => {
        const uploadedFile = {
          name: file.name,
          date: new Date().toLocaleString(),
          content: event.target.result,
        };

        setUploadedFiles((prevFiles) => [...prevFiles, uploadedFile]);
      };

      reader.readAsText(file);
    }
  };

  return (
    <div>
      <div
        className={`upload-container ${isDragging ? 'dragging' : ''}`}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        <input
          type="file"
          onChange={handleFileChange}
          multiple
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <div className="upload-icon">📤</div>
        <p className="upload-text">Drag and drop files or click to upload</p>
      </div>
      <div className="file-list">
        {uploadedFiles.map((file, index) => (
          <div key={index} className="file-item">
            <div className="file-info">
              <p className="file-name">{file.name}</p>
              <p className="upload-date">{file.date}</p>
            </div>
            <button className="view-content-button" onClick={() => alert(`Content:\n\n${file.content}`)}>
              <span > <ImDownload3 /> </span> Download
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploadComponent;
