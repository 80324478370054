import React, {useEffect, useState} from "react";
import { PiCaretRightBold } from "react-icons/pi";
import MembersPanel from "./MembersPanel";
import '../../styling/Dashboard.css';

const RightPanel = ({latestEvent, teamMembers}) => {
  const createDates = (latestEvent) => {
    if(latestEvent &&  latestEvent.length > 0){
      const events = latestEvent.slice(0,3);
      const formatted_dates = events.map((event, index) => {
        const dateString = event.eventDate;
        const date = new Date(dateString);
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
      });

      return formatted_dates;
    }
    
  };
  
  let dates = createDates(latestEvent);
  return (
    <div className="right-panel">
      <div >
          <h3
            style={{
              color: "#1C1154",
              marginTop: "0px",
              fontWeight: "500",
              fontSize: "20px",
              paddingTop: "10px",
            }}
          >
            New Bookings{" "}
          </h3>

          <div className="right-panel-content">
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              {latestEvent[0].eventName}
            </h4>
            <div className="right-panel-arrow-container">
              <span>{dates[0]}</span>{" "}
              <PiCaretRightBold className="right-panel-content-icon" />
            </div>

            <div style={{ paddingTop: "3px", paddingBottom: "15px" }}>
            {latestEvent[0].clientName}
            </div>
          </div>
          <div className="right-panel-content">
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              {latestEvent[1].eventName}
            </h4>
            <div className="right-panel-arrow-container">
              <span>{dates[1]}</span>{" "}
              <PiCaretRightBold className="right-panel-content-icon" />
            </div>

            <div style={{ paddingTop: "3px", paddingBottom: "15px" }}>
            {latestEvent[1].clientName}
            </div>
          </div>
          <div className="right-panel-content">
            <h4
              style={{
                color: "#1C1154",
                marginTop: "0px",
                marginBottom: "5px",
                fontWeight: "525",
              }}
            >
              {latestEvent[2].eventName}
            </h4>
            <div className="right-panel-arrow-container">
              <span>{dates[2]}</span>{" "}
              <PiCaretRightBold className="right-panel-content-icon" />
            </div>

            <div style={{ paddingTop: "3px", paddingBottom: "15px" }}>
            {latestEvent[2].clientName}
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>
            <button className="createButtonStyleRP">View All</button>
          </div>
        </div>
        <MembersPanel teamMembers={teamMembers}/>
    </div>
  );
};

export default RightPanel;
