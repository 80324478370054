import React, { useState } from "react";
import Table from "rc-table";
import "../../styling/Bookings.css";
import { PiCaretUpDownFill } from "react-icons/pi";
import moment, { now } from 'moment';

const EventsTable = ({event}) => {
    const [sortOrder, setSortOrder, selectedRows, setSelectedRows] = useState({});



    const columns = [
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Event Name
                </div>
            ),
            dataIndex: "eventName",
            key: "eventName",
            width: 100,
            align: "left",
            render: (text, record) => (
                <a
                    href={`/client/${record.clientId}`}
                    style={{ color: "#1C1154", textDecoration: "none" }}
                >
                    <h4 style={{ margin: "0px" }}> {text} </h4>
                </a>
            ),
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Client Name
                </div>
            ),
            dataIndex: "clientName",
            key: "clientName",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("client")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Event Status
                </div>
            ),
            dataIndex: "status",
            key: "status",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div className="custom-header" onClick={() => handleSort("status")}>
                    <PiCaretUpDownFill className="table-header-icon" />
                    Event Total
                </div>
            ),
            dataIndex: "expPaymentAmount",
            key: "expPaymentAmount",
            width: 100,
            align: "left",
        },
        {
            title: (
                <div
                    className="custom-header"
                    onClick={() => handleSort("paymentAmount")}
                >
                    <PiCaretUpDownFill className="table-header-icon" />
                    Event Date
                </div>
            ),
            dataIndex: "eventDate",
            key: "eventDate",
            width: 100,
            align: "left",
            render: (date) => <span>{moment(new Date(date)).format('MMM DD, YYYY')}</span>,
        }
    ];

   
    const handleSort = (column) => {
        setSortOrder((prevSortOrder) => ({
            ...prevSortOrder,
            [column]: prevSortOrder[column] === "asc" ? "desc" : "asc",
        }));
        // Add your sorting logic here
    };

    // const handleSelectAll = (checked) => {
    //     const allRows = data.map((item) => item.key); // Assuming you have a unique key for each row
    //     setSelectedRows(checked ? allRows : []);
    // };

    const handleDelete = () => {
        // Implement delete logic using selectedRows
        console.log("Deleting rows:", selectedRows);
    };

    const handleDownload = () => {
        // Implement download logic using selectedRows
        console.log("Downloading rows:", selectedRows);
    };

    const rowClassName = () => "custom-table-row";

    //   <tfoot>
    //   <tr>
    //     <td colSpan={columns.length}>
    //       {/* Checkbox */}
    //       <input type="checkbox" id="selectAll" />
    //       <label htmlFor="selectAll">Select All</label>

    //       {/* Gap */}
    //       <span style={{ margin: '0 4px' }} />

    //       {/* Download Button */}
    //       <button onClick={() => handleDownload()}>Download</button>
    //     </td>
    //   </tr>
    // </tfoot>

      const customRowClassName = (record, index) => {
        // Add a class to every row for the bottom border
        return 'table-row-with-border';
      };

    return (
        <div>
        <div className="events-table">
            <Table
                columns={columns}
                data={event}
                scroll={{ x: "100%" }}
                rowClassName={(record, index) => `${rowClassName(record, index)} ${customRowClassName(record, index)}`}
                components={{
                    header: {
                        cell: (props) => (
                            <th
                                {...props}
                                style={{
                                    borderBottom: "1px solid #D9D9D9",
                                    height: "50px",
                                    textAlign: "left",
                                }}
                            >
                                {props.children}
                            </th>
                        ),
                    },
                }}
            />
        </div>
        </div>
    );
};

export default EventsTable;
