import React, { useEffect, useState } from "react";
import { PiCaretRightBold } from "react-icons/pi";
import MembersPanel from "./MembersPanel";
import "../../styling/Analytics.css";
import AnalyticsDonut from "../charts/AnalyticsDonut";

const AnalyticsPanel = ({ latestEvent, teamMembers }) => {
  const createDates = (latestEvent) => {
    if (latestEvent && latestEvent.length > 0) {
      const events = latestEvent.slice(0, 3);
      const formatted_dates = events.map((event, index) => {
        const dateString = event.eventDate;
        const date = new Date(dateString);
        const options = { month: "long", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
      });

      return formatted_dates;
    }
  };

  let dates = createDates(latestEvent);
  return (
    <div className="right-panel-analytics">
      <div>
        <div className="right-panel-content-analytics">
          <div className="right-panel-arrow-container">
            <div>
              <h4
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  paddingTop: "0px",
                  fontWeight: '400'
                }}
              >
                YTD
              </h4>
              <div style={{ marginBottom: "15px" }}>Year to date</div>
            </div>
            <div
              style={{ color: "#1C1154", fontWeight: "bold", fontSize: "36px" }}
            >
              $10k
            </div>
            <span style={{ color: '#21A62C', fontSize: '15px' }}>800%</span>
          </div>
        </div>
        <div className="right-panel-content-analytics">
          <div className="right-panel-arrow-container">
            <div>
              <h4
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  paddingTop: "0px",
                  fontWeight: '400'
                }}
              >
                New Clients
              </h4>
              <div style={{ marginBottom: "15px" }}>For the last 30 days</div>
            </div>
            {/* <span>Year to date</span>{" "} */}
            <div
              style={{ color: "#1C1154", fontWeight: "bold", fontSize: "36px" }}
            >
              2
            </div>
            <span style={{ color: '#21A62C', fontSize: '15px' }}>10%</span>
          </div>
        </div>
        <div className="right-panel-content-analytics">
          <div className="right-panel-arrow-container">
            <div>
              <h4
                style={{
                  color: "#1C1154",
                  marginTop: "0px",
                  marginBottom: "5px",
                  paddingTop: "0px",
                  fontWeight: '400'
                }}
              >
                New Bookings
              </h4>
              <div style={{ marginBottom: "15px" }}>For the last 30 days</div>
            </div>
            {/* <span>Year to date</span>{" "} */}
            <div
              style={{ color: "#1C1154", fontWeight: "bold", fontSize: "36px" }}
            >
              5
            </div>
            <span style={{ color: '#21A62C', fontSize: '15px' }}>25%</span>
          </div>
        </div>
      </div>
      {/* <MembersPanel teamMembers={teamMembers} /> */}
      <div style={{ marginTop: '20px'}}>
      {/* <AnalyticsDonut/> */}
      </div>
    </div>
  );
};

export default AnalyticsPanel;
