import react from 'react';
import '../styling/SignUp.css'

const Password = () => {
    return(
        <div className='login'>
            <div style={{ padding: '13%'}}>

            <div style={{ fontSize: '35px', color: '#1C1154'}}>
            Try BoothBloom free for 7 days   
            </div>
            <div style={{ paddingLeft: '30%', color: '#4D4D4D'}}>
            <p> No Credit Card Required</p>
            </div>
    
            <input
              placeholder="  Password"
              style={{
                border: "hidden",
                border: "1px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
                height: "50px",
              }}
            ></input>
            <div>
                <p style={{ color: '#4D4D4D', fontWeight: '500'}}>Password Requirements:</p>
                <li style={{ listStyleType: 'circle', color: '#4D4D4D', fontWeight: '350', paddingBottom: '2px' }}>At least 8 characters</li>
                <li style={{ listStyleType: 'circle', color: '#4D4D4D', fontWeight: '350', paddingBottom: '2px' }}>One lowercase letter</li>
                <li style={{ listStyleType: 'circle', color: '#4D4D4D', fontWeight: '350', paddingBottom: '2px' }}>One uppercase letter</li>
                <li style={{ listStyleType: 'circle', color: '#4D4D4D', fontWeight: '350', paddingBottom: '2px' }}>One number or symbol</li>
            </div>
            <input
              placeholder="  Confirm Password"
              style={{
                border: "hidden",
                border: "1px solid #D9D9D9",
                fontSize: "15px",
                width: "100%",
                height: "50px",

                marginTop:'10px'
              }}
            ></input>
            <button
              style={{
                marginTop: "35px",
                height: "40px",
                width: "100%",
                background: "#260F99",
                border: "1px solid #43425D ",
                color: "#FFFFFF",
                fontSize: "17px",
                fontWeight: "450",
              }}
            >
              Next &gt;
            </button>
            <div style={{ paddingLeft: '30%', color: '#545454'}}>
            <p> Go Back to  <span style={{ textDecoration: 'underline' }}>login page</span></p>
            </div>
            </div>
        </div>
    );
};

export default Password;